.multi-range-slider .range-slider {
    position: relative;
    width: 100%;
    height: 35px;
    text-align: center;
}

.multi-range-slider .range-slider input {
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: -9px;
    width: 100%;
    outline: none;
    height: 52px;
    margin: 0;
    padding: 0;
}

.multi-range-slider .range-slider input::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
}

.multi-range-slider .range-slider input::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -moz-appearance: none;
    width: 9px;
}

.multi-range-slider .range-slider input::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: rgba(0, 0, 0, 1);
    border: 0;
}

.multi-range-slider .range-slider input:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
}

.multi-range-slider .range-slider input[type="range"]::-moz-focus-outer {
    border: 0;
}

.multi-range-slider .rangeValue {
    width: 30px;
}

.multi-range-slider .output {
    color: #fff;
    font-weight: 600;
    text-align: center;
    border-radius: 12px;
    background: #3EB6AE;
    cursor: pointer;
    position: absolute;
    padding: 4px 8px;
    text-align: center;
    border-radius: 8px;
    display: inline-block;
    font: bold 15px/30px Helvetica, Arial;
    bottom: 85%;
    transform: translate(-50%, 0);
    min-width: 52px;
}

.multi-range-slider .output.outputTwo {}

.multi-range-slider input[type="range"] {
    -webkit-appearance: none;
    background: none;
}

.multi-range-slider input[type="range"]::-webkit-slider-runnable-track {
    height: 5px;
    border: none;
    border-radius: 3px;
    background: transparent;
}

.multi-range-slider input[type="range"]::-ms-track {
    height: 5px;
    background: transparent;
    border: none;
    border-radius: 3px;
}

.multi-range-slider input[type="range"]::-moz-range-track {
    height: 5px;
    background: transparent;
    border: none;
    border-radius: 3px;
}

.multi-range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #555;
    margin-top: -6px;
    position: relative;
    z-index: 10000;
}

.multi-range-slider input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #555;
    margin-top: -4px;
    position: relative;
    z-index: 10000;
}

.multi-range-slider input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #555;
    margin-top: -4px;
    position: relative;
    z-index: 10000;
}

.multi-range-slider input[type="range"]:focus {
    outline: none;
}

.multi-range-slider .full-range,
.multi-range-slider .incl-range {
    width: 100%;
    height: 4px;
    left: 0;
    top: 21px;
    position: absolute;
    background: #fff;
}

.multi-range-slider .incl-range {
    background: #3EB6AE;
}

.multi-range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #3EB6AE;
    cursor: pointer;
}

.multi-range-slider input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #3EB6AE;
    cursor: pointer;
}

.multi-range-slider input[type="range"]::-webkit-slider-thumb,
.multi-range-slider input[type="range"]::-moz-range-thumb {
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.multi-range-slider input[type="range"]::-webkit-slider-thumb:after,
.multi-range-slider input[type="range"]::-moz-range-thumb:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #3EB6AE;
}

.button-container button {
    border: none;
    margin-top: 16px;
    box-shadow: none;
}

.button-container {
    width: fit-content;
    width: fit-content;
    display: grid;
    column-gap: 16px;
    grid-template-columns: auto auto auto auto auto auto;
}

.range-indicators {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.range-indicators .indicator {
    width: max-content;
    background: #fff;
    border-radius: 2px;
    position: relative;
    padding: 4px 8px;
    border-radius: 8px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .button-container {
        /* grid-template-columns: auto; */
        /* width: 100%; */
        display: flex;
        flex-wrap: wrap;
        /* gap: 10px; */
    }
    .button-container button {
     
    }
    .range-indicators {
        gap: 12px;
    }
    .range-indicators .indicator {
        font-size: 12px;
        /* padding: 12px 4px; */
        width: 100%;
        text-align: center;
    }
    .stepper .container {
        padding: 0 1rem;
    }
    
}

.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 20px auto;
  }
  
  .rtl {
    direction: rtl;
  }
  
  .slider-track {
    height: 6px;
    width: 100%;
    background: #e0e0e0;
    border-radius: 5px;
    position: relative;
  }
  
  .slider-thumb {
    height: 24px;
    width: 24px;
    background-color: #54bfbf;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
  
  .slider-thumb-label {
    position: absolute;
    top: -36px;
    background-color: #54bfbf;
    color: white;
    padding: 3px 5px;
    border-radius: 10px;
    font-size: 12px;
    width: max-content;
    text-align: center;
  }

    .slider-thumb-label::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #54bfbf;
    }
  
  .slider-ticks {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }
  
  .slider-tick {
    color: #888;
    font-size: 14px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .range-button {
    padding: 5px 10px;
    border: none;
    background-color: #54bfbf;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .range-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  
  .rtl .slider-track {
    direction: rtl;
  }
  
  .rtl .slider-thumb-label::after {
    left: auto;
    right: 50%;
    margin-right: -5px;
  }
  