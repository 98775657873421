
.card_about {
    border-radius: 24px;
    /* background: var(--Warning-300, #FEF0C7);     */
    display: flex;
padding: 40px 32px;
flex-direction: column;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
overflow: hidden;
}


.card_about h3 {
    color: var(--Gray-900, #2F1952);
    text-align: center;
    
    /* H4/Black */
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 150%; /* 48px */
}
.card_about p {
    color: var(--Gray-900, #2F1952);
text-align: center;

/* Body/Large/Regular */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
letter-spacing: 0.2px;
}

/* loop card_about and each card_about has a different background color */
.card_about_1{
    background: var(--Primary-200, #D1EFED);}
.card_about_2{
    background: var(--Secondary-200, #DBCEF3);}
.card_about_3{
    background: var(--Warning-300, #FEF0C7);
}

.card_values
{
    display: flex;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
border-radius: 16px;
background: #FFF;
width: 100%;
}
.card_values .icon {
    border-radius: 32px;
background: var(--Primary-600, #3EB6AE);
display: flex;
padding: 16px;
justify-content: center;
align-items: flex-start;
gap: 8px;
}