.order-item{
    padding: 24px ;
    border-radius: 16px;
    gap: 8px;
    background: white;
    width: 100%;

}


.order-item .buy_btn {
    /* width: 154px; */
    height: 45px;
    padding: 12px 64px 12px 64px;
    border-radius: 16px;
    gap: 10px;
}

.order-item .info-logo {
    background: #D4ECEB66;
padding: 10px;
border-radius: 48px;
gap: 8px;
}

.order-item .info 
{
    font-size: 12px;
}



.order-item .price_item {
    color: #3EB6AE;
    font-size: 1rem;
    font-weight: 600;
}
.order-item .title_
{
    color: var(--Gray-900, #2F1952);

/* H5/Bold */
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 36px */
}

.order-item_chassy{
    border-bottom: 1px solid #E8E8E8;
    /* font-size: 16px; */
}

.order-item .buy_btn {
    border-radius: 8px !important;
    /* 64 */
    height: 64px !important;
    padding: 12px 64px 12px 64px !important;
}