.details-card-out {
    /* background-color: white; */
    background-position: top right;
    background-repeat: no-repeat;
    
}
.full-height {
    height: 100%;
}
.details-card-in {
    background-color: white;
    border-radius: 10px;
    padding: 24px;
    border-radius: 18px 88px 18px 18px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
}

.no-border {
    border-radius: 18px 88px 0 0 !important;
}

[dir="rtl"] .no-border {
    border-radius: 88px 18px 0 0 !important;
}

[dir="rtl"] .details-card-in {
    border-radius: 88px 18px 18px 18px;
}

[dir="rtl"] .details-card-out {
    background-position: top left;
    background-repeat: no-repeat;
}

.noEdge {
    border-radius: 18px !important;
}
