.group-20464-LKw {
    width: 100%;
    height: 800px;
    position: relative;
    /* fonts-family: Poppins, 'Source Sans Pro'; */
}

.group-20464-LKw .frame-185134-Jg5 {
    box-sizing: border-box;
    padding: 4.2739rem 1.2rem;
    width: 29.5534rem;
    height: 37.4478rem;
    position: absolute;
    left: 22.7396rem;
    top: 15.2956rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: -2.4rem 1.2rem 4.4rem rgba(0, 0, 0, 0.0900000036);
    background-color: #ffffff;
    border-radius: 1.4rem;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m {
    margin: 0rem 4.277rem 0rem 4.277rem;
    width: calc(100% - 8.5534rem);
    height: 18.6rem;
    position: relative;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .ellipse-1-6ds {
    width: 18.6rem;
    height: 18.6rem;
    position: absolute;
    left: 0rem;
    top: 0;
    object-fit: contain;
    vertical-align: top;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .ellipse-2-zjF {
    width: 18.6rem;
    height: 18.6rem;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
    vertical-align: top;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH {
    width: 9.7rem;
    height: 9.2rem;
    position: absolute;
    left: 4.4rem;
    top: 4.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184965-RpZ {
    margin: 0rem 1.6rem 0.8rem 1.6rem;
    box-sizing: border-box;
    padding-left: 0.2rem;
    width: calc(100% - 3.2rem);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184965-RpZ .icon-outline-cloud-uUq {
    margin: 0rem 0.6rem 0rem 0rem;
    width: 1.2rem;
    height: 0.9333rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184965-RpZ .data-xT7 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #3eb6ae;
    /*  */
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184966-HnD {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184966-HnD .gb-Dvm {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.5;
    color: #212121;
    /*  */
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .circle-progress-bar-f2m .frame-184967-XUH .frame-184966-HnD .of-40-gb-left-vqB {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #212121;
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh {
    box-sizing: border-box;
    padding: 1.2rem 0;
    width: 100%;
    row-gap: 1.6rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 0.8rem;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb {
    width: 100%;
    height: 3.3rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-RvM {
    margin: 0.85rem 15.653rem 0.85rem 0rem;
    box-sizing: border-box;
    padding: 0.05rem 0rem 0.05rem 0.2rem;
    height: calc(100% - 1.7rem);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-RvM .icon-outline-clock-WS1 {
    margin-right: 0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-RvM .validity-pSh {
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #3eb6ae;
    white-space: nowrap;
    flex-shrink: 0;
}

i {
    color: black;
    font-weight: 100;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-184970-WaR {
    height: 100%;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-184970-WaR .days-rPP {
    text-align: right;
    display: flex;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #212121;
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .frame-184971-WHb .frame-184970-WaR .remaining-97b {
    text-align: right;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: #212121;
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .rectangle-progress-bar-Dt9 {
    width: 100%;
    background-color: rgba(62, 182, 174, 0.25);
    border-radius: 0.8rem;
    flex-shrink: 0;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .rectangle-progress-bar-Dt9 .rectangle-ZBK {
    width: 19.4465rem;
    height: 0.6rem;
    background-color: #3eb6ae;
    border-radius: 0.8rem;
}

.group-20464-LKw .frame-185134-Jg5 .cardv3-Cnh .rectangle-cvH {
    width: 12.5rem;
    height: 0.8rem;
    background-color: #e5e5e7;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-TR7 {
    box-sizing: border-box;
    padding: 1.6rem 1.877rem 1.721rem 1.6rem;
    width: 23.1772rem;
    height: 10.121rem;
    position: absolute;
    left: 46.3216rem;
    top: 20.5585rem;
    row-gap: 1.2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 3.2rem 3.2rem 6.4rem rgba(1, 16, 39, 0.1599999964);
    background-color: #ffffff;
    border-radius: 1.6rem;
}

.group-20464-LKw .contract-signed-TR7 .overview-list-GNZ {
    margin-right: 7.8rem;
    width: calc(100% - 7.8rem);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-TR7 .overview-list-GNZ .task-square-nrh {
    margin-right: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-TR7 .overview-list-GNZ .usage-screen-ghB {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4285714286;
    letter-spacing: 0.007rem;
    color: #09080d;
    /* font-family: Nunito Sans, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-TR7 .rectangle-aXf {
    width: 100%;
    height: 1rem;
    background-color: #e9ebec;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-TR7 .rectangle-WAR {
    width: 11rem;
    height: 1rem;
    background-color: #3eb6ae;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-yZo {
    box-sizing: border-box;
    padding: 1.6rem 1.877rem 1.721rem 1.6rem;
    width: 23.1772rem;
    height: 10.121rem;
    position: absolute;
    left: 46.4228rem;
    top: 7.3rem;
    row-gap: 1.2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 3.2rem 3.2rem 6.4rem rgba(1, 16, 39, 0.1599999964);
    background-color: #ffffff;
    border-radius: 1.6rem;
}

.group-20464-LKw .contract-signed-yZo .overview-list-Dyw {
    margin-right: 4.1rem;
    width: calc(100% - 4.1rem);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-yZo .overview-list-Dyw .task-square-ZH7 {
    margin-right: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-yZo .overview-list-Dyw .more-10-providers-sob {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4285714286;
    letter-spacing: 0.007rem;
    color: #09080d;
    /* font-family: Nunito Sans, 'Source Sans Pro'; */
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-yZo .overview-list-Dyw .more-10-providers-sob .more-10-providers-sob-sub-0 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4285714286;
    letter-spacing: 0.007rem;
    color: #09080d;
}

.group-20464-LKw .contract-signed-yZo .overview-list-Dyw .more-10-providers-sob .more-10-providers-sob-sub-1 {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4285714286;
    letter-spacing: 0.007rem;
    color: #09080d;
}

.group-20464-LKw .contract-signed-yZo .rectangle-ir9 {
    width: 100%;
    height: 1rem;
    background-color: #e9ebec;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .contract-signed-yZo .rectangle-FLH {
    width: 11rem;
    height: 1rem;
    background-color: #3eb6ae;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd {
    box-sizing: border-box;
    padding: 1.6rem 3.077rem 1.991rem 1.6rem;
    width: 23.1772rem;
    height: 15.9912rem;
    position: absolute;
    left: 12.6186rem;
    top: 48.9986rem;
    box-shadow: 3.2rem 3.2rem 6.4rem rgba(1, 16, 39, 0.1599999964);
    background-color: #ffffff;
    border-radius: 1.6rem;
}

.group-20464-LKw .total-freeelance-uvd .overview-list-nUd {
    margin-bottom: 1.2rem;
    width: 100%;
    height: 4.8rem;
    display: flex;
    align-items: center;
}

.group-20464-LKw .total-freeelance-uvd .overview-list-nUd .group-20453-u3T {
    margin-right: 1.2rem;
    width: 4.8rem;
    height: 4.8rem;
    position: relative;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd .overview-list-nUd .employees-number-1cH {
    margin: 0.6rem 0;
    width: 12.5rem;
    height: calc(100% - 1.2rem);
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd .overview-list-nUd .employees-number-1cH .recharge-your-plan-7fK {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4285714286;
    letter-spacing: 0.007rem;
    color: #09080d;
    /* font-family: Nunito Sans, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd .overview-list-nUd .employees-number-1cH .rectangle-oHF {
    width: 100%;
    height: 0.8rem;
    background-color: #e5e5e7;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd .metric-number-vMs {
    width: 12.5rem;
}

.group-20464-LKw .total-freeelance-uvd .metric-number-vMs .g-rmK {
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4444444444;
    letter-spacing: 0.009rem;
    color: #09080d;
    /* font-family: Nunito Sans, 'Source Sans Pro'; */
    white-space: nowrap;
}

.group-20464-LKw .total-freeelance-uvd .metric-number-vMs .number-badge-AGD {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.group-20464-LKw .total-freeelance-uvd .metric-number-vMs .number-badge-AGD .expires-on--VpH {
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.006rem;
    color: #a2a1a7;
    /* font-family: Inter, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
}

.group-20464-LKw .total-freeelance-uvd .metric-number-vMs .number-badge-AGD .rectangle-CCu {
    width: 100%;
    height: 0.8rem;
    background-color: #e5e5e7;
    border-radius: 1.5rem;
    flex-shrink: 0;
}

.packs-list {
    /* position: absolute; */
    /* width: 50vw; */
    display: flex;
    overflow-x: scroll;
}

@media (max-width: 992px) {
    .packs-list {
        /* width: 100vw; */
    }
}

.group-20464-LKw {
    scale: .6;
    transform: translate(-50%, -30%);
}

@media (max-width: 1140px) {
    .group-20464-LKw {
        scale: .3;
        /* transform: translate(-50%, -30%); */
        display: table-footer-group;
        height: 300px;
    }
}

@media (max-width: 1376px) {
    .group-20464-LKw {
        scale: .4;
        /* transform: translate(-50%, -30%); */
        display: table-footer-group;
        height: 300px;
    }
}


/*  */


/* width */

.packs ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}


/* Handle */

.packs ::-webkit-scrollbar-thumb {
    background: #3EB6AE;
    border-radius: 10px;
    height: 4px;
}


/* Handle on hover */

.packs ::-webkit-scrollbar-thumb:hover {
    background: #28978f;
}