/* side_info */
.side_info_bg {
    width: 100%;
    height: 192px;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    margin-bottom: -100px;
}

.side_info_item
{
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    margin: 16px;
}
.side_info_item h3
{
font-size: 24px;
font-weight: 500;
/* line-height: 36px; */
letter-spacing: -0.02em;
}

.side_info_item .price_item 
{
font-size: 16px;
font-weight: 600;
/* line-height: 21px; */
letter-spacing: -0.02em;

}
.store .promo_code input
{
    border: none;
}

.store .promo_code span
{
    opacity: 0.5;
    cursor: pointer;
}

.store .promo_code span:hover
{
    opacity: 1;
}

.table_info .table_item
{
opacity: 0.5;
margin: 16px 0;
}

.table_info .devider
{
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: 0.05;
    margin: 16px 0;
}

.no_bg{
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
}

@media (max-width: 991px) {
    .side_info_item{
        margin: 24px 12px;
    }
}