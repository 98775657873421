/* .select-outlined {
    width: 100%;
    background: transparent;
    border: #d8d8d8 1.5px solid;
    border-radius: 10px;
    -webkit-appearance: none;
    appearance: none;
}

.select-wrapper {
    position: relative;
}

.select-wrapper::after {
    content: "⌄";
    font-size: 1rem;
    top: 12px;
    right: 19px;
    position: absolute;
} */

.title {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    text-align: center;
    color: #3EB6AE;
    /* max-width: 900px; */
    margin: 0 auto;
}

.plan_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.plan_title:hover {
    overflow: visible;
    text-overflow: unset;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    line-clamp: unset;
    -webkit-box-orient: unset;
}

.title .para {
    color: var(--Gray-900, #2F1952);
text-align: center;

/* H3/Black */
/* font-family: Poppins; */
font-size: 40px;
font-style: normal;
font-weight: 900;
line-height: 150%; /* 60px */
}

.title h1 {
    font-weight: 500;
    font-size: 22px;
}


/* on mobile */

@media (max-width: 768px) {
    .title .para {
        font-size: 20px;
    }
    .title h1 {
        font-size: 1rem;
    }
}

.title a {
    font-weight: 400;
    font-size: 1rem;
}

.title a i {
    font-size: 1rem;
    color: #3EB6AE;
    margin-left: 12px;
}

.title {
    margin-top: 46px;
    margin-bottom: 50px;
}

.pagination .page-link.active {
    color: #FFF;
    background: #3EB6AE;
    opacity: 1;
}

.pagination .page-link {
    color: #FFF;
    background: #3EB6AE;
    /* color: #797979; */
    opacity: 0.5;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin: 0 1.5rem;
    border: none;
    /* background: transparent; */
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 10px !important;
}