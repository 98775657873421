.cart {
    /* height: 198px; */
    max-height: calc(100vh - 32px);
    /* width: 400px; */
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e5e5;
    position: fixed;
    right: 16px;
    bottom: 16px;
    padding: 10px;
    z-index: 100;
    border-radius: 16px;
    /* overflow: hidden; */
    background-color: white;
    transition: width .3s ease-in-out, height .3s ease-in-out, background-color .3s ease-in-out;
}


/* on mobile */

@media (max-width: 768px) {
    .cart {
        width: calc(100vw - 32px);
        height: calc(100vh - 32px);
    }
}

.cart .plan-price
{
    font-size: 16px;
    font-weight: 600;
    color: #3eb6ae;
}


/* animation for the element */

.cartAnimation {
    animation: cartAnimation .2s;
    animation-fill-mode: forwards;
}

.cart.closed {
    width: 200px;
    height: 64px;
    background-color: #3eb6ae;
    position: fixed;
    right: 194px;
    bottom: 16px;
    padding: 10px;
    z-index: 100;
    border-radius: 16px;
    /* overflow: hidden; */
    transition: width .3s ease-in-out, height .3s ease-in-out, background-color .3s ease-in-out;
}
.nouser .cart.closed {
    right: 16px !important;
}

.cart.closed .cart-body,
.cart.closed .cart-footer,
.cart.closed .cart-header {
    display: none;
}

.cart.closed .inner-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: white;
}

.cart.closed .inner-button .cart-count {
    background-color: white;
    color: black;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart .inner-button {
    display: none;
}

.cart-highlight {
    background-color: #35ddb3 !important;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05) !important;
}

.cart .cart-body {
    display: flex;
    max-width: 680px;
    overflow-x: scroll;
    padding: 16px;
}

.cart .cart-body .plan-in-card {
    min-width: 192px;
    max-width: 192px;
    margin-right: 16px;
}

.cart .cart-body .plan-in-card .plan-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.cart .cart-body .plan-in-card .plan-type {
    font-size: 14x;
    font-weight: 400;
    margin-bottom: 8px;
    line-height: 0.6;
    color: #a0a0a0;
}

.cart .empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #a0a0a0;
    padding: 16px 48px;
    flex-direction: column;
}


/* .cart .empty-cart button {
    margin-top: 16px;
    background-color: #3eb6ae;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
} */

.cart .cart-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #e5e5e55c;
}

.dragged {
    position: absolute;
    z-index: 1000;
    animation: moveToCart 1s;
    animation-fill-mode: forwards;
}

.cart .close-button {
    position: absolute;
    top: -16px;
    left: -16px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e5e5;
    font-size: 20px;
}

.cart .close-button:hover {
    background-color: #e5e5e5;
    
}

.cart.closed .close-button {
    display: none;
}

@media (max-width: 768px) {
    .cart-body {
        padding: 0;
        flex-direction: column;
    }
    .cart .cart-body .plan-in-card {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        min-width: 100%;
    }
    .cart {
        display: grid;
        align-content: space-between;
    }
}

@media (max-width: 991px) {
    .cart.closed {
        height: 50px;
        border-radius: 100px;
        border: 0;
        width: 166px;
        bottom: 20px;
    }
}

.border-right-plan{
    border-right: 1px solid #e5e5e5;
    height: 100%;
    padding-right: 16px;
}

.border-left-plan{
    border-left: 1px solid #e5e5e5;
    height: 100%;
    padding-left: 16px;
}