#notfound {
    position: relative;
    height: calc(100vh - 100px);
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
}

.notfound_ h1 {

    font-size: 64px !important;
    width: 638px !important;
    font-weight: 200;
    margin: 0px;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 51%;
    top: 35px !important;
    transform: translate(-50%, -50%);
}

.notfound .notfound-404 {
    position: relative;
    height: 200px;
    margin: 0px auto 20px;
    z-index: -1;
}

.notfound .notfound-404 h1 {
    font-size: 236px;
    font-weight: 200;
    margin: 0px;
    color: #211b19;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
    font-size: 28px;
    font-weight: 400;
    text-transform: uppercase;
    border: 2px solid #211b19;
    border-radius: 16px;
    color: #211b19;
    background: #fff;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
}

.notfound a {
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding: 13px 23px;
    background: #3EB6AE;
    border-radius: 25px;
    font-size: 18px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    color: #3EB6AE;
    background: #211b19;
}

@media only screen and (max-width: 767px) {
    .notfound .notfound-404 h1 {
        font-size: 148px;
    }
}

@media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
        height: 148px;
        margin: 0px auto 10px;
    }
    .notfound .notfound-404 h1 {
        font-size: 86px;
    }
    .notfound .notfound-404 h2 {
        font-size: 1rem;
    }
    .notfound a {
        padding: 7px 15px;
        font-size: 16px;
    }
}