
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
}
.card_total
{
    
    padding: 24px !important;
    border: 1px dashed var(--Primary-600, #3EB6AE) !important;
    margin-bottom: 16px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-direction: row !important;
}
.wpwl {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
}

.wpwl-form-card {
    box-shadow: none;
    background: none;
    border: none;
    position: relative;
    padding-top: 134px;
    /* margin-top: -71px; */
}

.wpwl-form {
    max-width: 100%;

}
.wpwl-label {
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;

}
.wpwl-form input{
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    direction: ltr !important;
}

.wpwl-control-cardNumber {
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;

    background: white url("https://raw.githubusercontent.com/ettouzany/myphotos/main/t/credit-card.svg") no-repeat 14px center !important;
    ;
}

.wpwl-control-expiry {
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;

    background: white url("https://raw.githubusercontent.com/ettouzany/myphotos/main/t/calendar.svg") no-repeat 15px center !important;
    ;
}

.wpwl-control-cardHolder {
    background: white url("https://raw.githubusercontent.com/ettouzany/myphotos/main/t/user.svg") no-repeat 16px center !important;
    ;
}

.wpwl-control-cvv {
    background: white url("https://raw.githubusercontent.com/ettouzany/myphotos/main/t/cvv.svg") no-repeat 16px center !important;
    background-size: 20px !important;
}

.wpwl-group-cardNumber,
.wpwl-group-cardHolder {
    width: 100% !important;
    padding: 0 !important;
}

.wpwl-control-cardNumber,
.wpwl-control-expiry,
.wpwl-control-cardHolder,
.wpwl-control-cvv,
.wpwl-control-brand {
    padding: 16px 16px 16px 50px;
    background-color: white;
    height: 57px;
    /* border: none; */
    border-radius: 12px;
    outline: none;
    direction: ltr;
    /* font-family: 'Poppins'; */
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 180%;
}

.wpwl-control-cardNumber:focus,
.wpwl-control-expiry:focus,
.wpwl-control-cardHolder:focus,
.wpwl-control-cvv:focus {
    border: 1px solid #3EB6AE;
}

.wpwl-button-pay {
    width: 100%;
    height: 57px;
    background: #3EB6AE;
    border-radius: 8px;
    border: none;
    outline: none;
    color: white;
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 180%;
    /* cursor: pointer; */
}
.wpwl-form{
    margin: 0 !important;
}

.wpwl-button-pay:hover,
.wpwl-button-pay:focus {
    background: #3EB6AE;
    opacity: 0.8;
    outline: 0!important;
    border: none;
}

.wpwl-label {
    width: 100%;
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    line-height: 40px;
}

 .wpwl-label-cvv::after {
    content: "!";
    color: white;
    background: #3EB6AE;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.wpwl-control-brand {
    padding: 16px 16px 16px 16px;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
}

.wpwl-wrapper-brand {
    padding: 0px 0px 0px 0px;
    width: 100%;
}

.wpwl-group-brand {
    height: 67px;
}
/* 480 screen */

.wpwl-group-expiry,
.wpwl-group-cvv {
    width: 50% !important;
    float: left;
}

.wpwl-group-expiry {
    padding-right: 20px;
}

.wpwl-group-cardHolder {
    position: absolute;
    top: 20px;
}
.wpwl-group-cardHolder {
    width: calc(100%);
}
.wpwl-group.wpwl-group-brand {
    position: absolute;
    top: 184px;
    right: 20px;
    z-index: 1;
    opacity: 0.7;
    width: max-content;
}
.wpwl-label-brand,.wpwl-wrapper-brand
{
  display: none !important;
}
/* rtl */
/* [dir="rtl"] .wpwl-group.wpwl-group-brand {
    right: auto;
    left: 8px;
} */
.wpwl-wrapper{
    width: 100% !important;
}

.total
{
    padding: 16px;
    border-radius: 8px;
    display: none;
    justify-content: space-between;
    background: white;
}

.total-price
{
    font-size: 1rem;
    font-weight: 600;
    color: #3EB6AE;
}

.btn-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 12px;
    gap: 10px;
    border-radius: 4px;
    flex: none;
    order: 6;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    border: 0;
    /* background: #FFFFFF; */
    height: 50px;
}

.credit_btn {
    background: #3EB6AE;
    color: white;
    cursor: pointer;
}


@media (max-width: 991px) {
    .total
    {
        
        display: flex !important;
    }
}

/* show only on safari */





.apple-pay-button {
    display: block;
    -webkit-appearance: -apple-pay-button;
    appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
    height: 50px;
    width: 100%;
}
.apple-pay-button-black {
    -apple-pay-button-style: black;
}
.apple-pay-button-white {
    -apple-pay-button-style: white;
}
.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}


.desktop-view .wpwl-group-expiry {
    padding-right: 40px;
}
.desktop-view .wpwl-control {
    margin-bottom: 24px;
    line-height: 180%;
    height: 72px !important;
}

.desktop-view .wpwl-form-card {
    padding-top: 168px !important;
}

.desktop-view 
.wpwl-label {
    font-weight: 600;
    line-height: 40px;
    font-size: 16px;
}

.desktop-view .btn-block {
    height: 58px;
    min-width: 144px;
    padding: 0 28px;
    border-radius: 8px;
}

.desktop-view .wpwl-group.wpwl-group-brand {
    top: 228px;
}

/* .wpwl-group-submit
{
    z-index: -1;
} */



.cko-payment-form .card
{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    background: #FFF;
    border: 0;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);

}
.cko-payment-form .header_card
{
    cursor: pointer;
    color: black;
    /* Body/Medium/Regular */
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.cko-payment-form .header_card i
{
    cursor: pointer;
    color: black;
    
}
.cko-payment-form .bg-gray
{
    color: var(--Gray-400, #B5B3B7) !important;
}

.cko-payment-form .bg-gray .header_card
{
    color: var(--Gray-400, #B5B3B7) !important;   
}
.cko-payment-form .bg-gray .header_card i
{
    color: var(--Gray-400, #B5B3B7) !important;   
}

.cko-payment-form .paymentMethod
{
    border-top: 1px solid #EDEDED;
    width: 100%;
    margin-top: 8px;
}

.cko-payment-form .big-text
{
    font-size: 23px;
font-style: normal;
font-weight: 600;

}

.wpwl-apple-pay-button{
    -webkit-appearance: -apple-pay-button !important;
    appearance: -apple-pay-button !important;
    -apple-pay-button-style: black;
    -apple-pay-button-type: plain;
    height: 60px;
    width: 100%;
    border-radius: 8px;
}

.cko-payment-form .collapse {
    width: 100%;
}
.cko-payment-form
.wpwl-form-has-inputs
{
    padding: 0 !important;
    border: 0 !important;
    border-radius:0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.wpwl-brand-STC_PAY
{
    display: none !important;
}

.wpwl-wrapper-mobilePhone input{
    padding: 16px;
    background-color: white;
    height: 57px;
    /* border: none; */
    border-radius: 12px;
    outline: none;
    direction: ltr;
    /* font-family: 'Poppins'; */
    font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 180%;
}

.wpwl-container-virtualAccount-STC_PAY
{
    padding-top: 16px !important;
}

@media (min-width: 991px) {
    .wpwl-button-pay
    {
        max-width: 222px;
        float: unset;
    }
}

.terms_ a 
{
    color: #3EB6AE;
    cursor: pointer;
    margin: 8px;
    /* remove underline */
    text-decoration: none;

}

.terms_for_card 
{
    margin-bottom: 18px;
    display: block;
}


.price_total
{
    color: var(--Primary-600, #3EB6AE);

/* H6/Medium */
font-family: 'DINNextLTArabic', 'Poppins', sans-serif !important;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
}