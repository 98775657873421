.order {
    /* height: 198px; */
    max-height: calc(100vh - 32px);
    /* width: 400px; */
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e5e5;
    position: fixed;
    right: 16px;
    bottom: 16px;
    padding: 10px;
    z-index: 100;
    border-radius: 16px;
    /* overflow: hidden; */
    background-color: white;
    transition: width .3s ease-in-out, height .3s ease-in-out, background-color .3s ease-in-out;
}

.order-drawer {
    background-color: #F7F7F7 !important;
}
/* on mobile */

@media (max-width: 768px) {
    .order {
        width: calc(100vw - 32px);
        height: calc(100vh - 32px);
    }
}


/* animation for the element */

.orderAnimation {
    animation: orderAnimation .2s;
    animation-fill-mode: forwards;
}

.order.closed {
    width: 162px;
    height: 64px;
    background-color: #3eb6ae;
    position: fixed;
    right: 16px;
    bottom: 16px;
    padding: 16px;
    z-index: 100;
    border-radius: 16px;
    /* overflow: hidden; */
    transition: width .3s ease-in-out, height .3s ease-in-out, background-color .3s ease-in-out;
}
.nouser .order.closed {
    display: none !important;
}

.order.closed .order-body,
.order.closed .order-footer,
.order.closed .order-header {
    display: none;
}

.order.closed .inner-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: white;
}

.order.closed .inner-button .order-count {
    background-color: white;
    color: black;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order .inner-button {
    display: none;
}

.order-highlight {
    background-color: #35ddb3 !important;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05) !important;
}

.order .order-body {
    display: flex;
    max-width: 680px;
    overflow-x: scroll;
    padding: 16px;
}

.order .order-body .plan-in-order {
    min-width: 192px;
    max-width: 192px;
    margin-right: 16px;
}

.order .order-body .plan-in-order .plan-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.order .order-body .plan-in-order .plan-type {
    font-size: 14x;
    font-weight: 400;
    margin-bottom: 8px;
    line-height: 0.6;
    color: #a0a0a0;
}

.order .empty-order {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #a0a0a0;
    padding: 16px 48px;
    flex-direction: column;
}


/* .order .empty-order button {
    margin-top: 16px;
    background-color: #3eb6ae;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
} */

.order .order-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid #e5e5e55c;
}


.order .close-button {
    position: absolute;
    top: -16px;
    left: -16px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e5e5;
    font-size: 20px;
}

.order .close-button:hover {
    background-color: #e5e5e5;
}

.order.closed .close-button {
    display: none;
}

@media (max-width: 768px) {
    .order-body {
        padding: 0;
        flex-direction: column;
    }
    .order .order-body .plan-in-order {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        min-width: 100%;
    }
    .order {
        display: grid;
        align-content: space-between;
    }
}

@media (max-width: 991px) {
    .order.closed {
        height: 50px;
        border-radius: 100px;
        border: 0;
        width: 166px;
        bottom: 20px;
    }
}

.order-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #e5e5e55c;

}

.order-header span {
        color: var(--Gray-900, #2F1952);
        display: flex;
        align-items: center;

        /* Body/Medium/Semibold */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
    }
    .order-header  span::before {
        content: '';
        width: 16px;
        height: 32px;
        border-radius: 4px;
        background: #3EB6AE;
        display: inline-block;
        margin-right: 8px;

    }
    .order-header  svg {
        cursor: pointer;
    }
.progress-bar 
{
    height: 88px;
    background: transparent;
    position: relative;
}


.progress-bar .progress-bar-inner
{
    height: 4px;
    border-radius: 8px;
    background: #9E9E9E;
    margin: 32px;
}

.progress-bar .progress-bar-inner .progress-bar-inner-fill
{
    height: 100%;
    border-radius: 8px;
    background: #632FC6;
    transition: width 0.3s ease-in-out;
}

.progress-bar .progress-bar-steps
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
}



.progress-bar .progress-bar-steps .progress-bar-step
{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #9E9E9E;
    cursor: pointer;
    position: relative;
}


.progress-bar .progress-bar-steps .progress-bar-step-title
{
    
    font-size: 12px;
    font-weight: 600;
    color: #632FC6;
    margin-top: 8px;
    position: absolute;
    top: 33px;
    width: 100px;
    text-align: center;
    left: -50px;

}

.progress-bar .progress-bar-steps .progress-bar-step.active
{
    background: #632FC6;
}