    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        font-family: 'DINNextLTArabic', 'Poppins', sans-serif;
    }
    
     :root {
        --start: left;
        --end: right;
    }
    
    @media (max-width: 768px) {
        strong {
            font-size: 1rem;
        }
    }
    
    [dir="rtl"] {
        --start: right;
        --end: left;
    }
    
    h1 {
        font-weight: 700;
        font-size: 3.5rem;
    }
    
    h2 {
        /* font-family: 'Poppins'; */
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
    }
    
    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 28px;
    }
    
    .c-primary {
        color: #3EB6AE;
    }
    
    .c-secondary {
        color: #F2F2F2;
    }
    
    .c-black {
        color: #2F1952;
    }
    
    .App {
        text-align: center;
    }
    
    .App-logo {
        height: 40vmin;
        pointer-events: none;
    }
    
    .bg-element {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    
    .bg-element--1 {
        /* background: rgba(62, 182, 174, 0.16); */
        /* filter: blur(400px); */
        background: rgb(62, 182, 174);
        background: radial-gradient(circle, rgba(62, 182, 174, 0.22) 0%, rgba(255, 255, 255, 0) 100%);
        min-height: 100vh;
    }
    
    .bg-element--2 {
        /* background: rgba(62, 182, 174, 0.16); */
        /* filter: blur(400px); */
        background: rgb(255, 153, 153);
        background: linear-gradient(90deg, rgba(255, 153, 153, 0.100647759103641) 0%, rgba(200, 200, 200, 0.175) 50%, rgba(255, 153, 153, 0.1006950280112045) 100%);
        min-height: 100vh;
        overflow: scroll;
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
    }
    
    @media (prefers-reduced-motion: no-preference) {
        .App-logo {
            animation: App-logo-spin infinite 20s linear;
        }
    }
    
    .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .App-link {
        color: #61dafb;
    }
    
    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    /* compare backdrop start */
    
    .offcanvas-end {
        margin: 16px;
        border-radius: 16px;
        box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #e5e5e5;
    }
    /* compare backdrop end */
    /* Filtter start */
    
    .input-group input {
        border: 1px solid #eff1f3;
        border-radius: 8px;
        outline: none;
    }
    
    .input-group input::placeholder {
        color: #959EAD;
        ;
    }
    
    .input-group input:focus {
        border: 1px solid #3EB6AE;
        outline: none !important;
    }
    
    textarea:focus,
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="datetime"]:focus,
    input[type="datetime-local"]:focus,
    input[type="date"]:focus,
    input[type="month"]:focus,
    input[type="time"]:focus,
    input[type="week"]:focus,
    input[type="number"]:focus,
    input[type="email"]:focus,
    input[type="url"]:focus,
    input[type="search"]:focus,
    input[type="tel"]:focus,
    input[type="color"]:focus,
    .uneditable-input:focus {
        border-color: #3EB6AE;
        box-shadow: none;
        outline: 0 none;
    }
    
    .button-select {
        border: 1px solid #eff1f3;
        border-radius: 2000px;
        padding: 8px 16px;
        font-size: smaller;
        background: transparent;
    }
    
    .button-select.active {
        border: 1px solid #3EB6AE;
        background: #3EB6AE;
        color: #fff;
    }
    
    .button-select:hover {
        border: 1px solid #3EB6AE;
    }
    /* displays */
    
    .dis-1 {
        margin-bottom: 4.8rem;
        display: flex;
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 1.5;
        color: #060707;
        /* font-family: Poppins, 'Source Sans Pro'; */
        white-space: nowrap;
    }
    /* @media (min-width: 1400px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            max-width: 1526px;
        }
    } */
    
    [dir=rtl] .pe-3 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }
    
    [dir=rtl] .ms-3 {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }
    
    [dir=rtl] .text-end {
        text-align: left !important;
    }
    
    [dir=rtl] .text-start {
        text-align: right !important;
    }
    /* [dir=rtl] .text-md-start {
        text-align: right !important;
    }
    
    [dir=rtl] .text-md-end {
        text-align: left !important;
    } */