body {
    background-color: #FCFAFD;
}


/* .plan-details {
    scale: 0.95;
} */

.cardsim-bg {
    background-color: white;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-card-out p {
    text-align: initial;
}

.icon_det {
    min-width: 22px;
}

.text-lg-start {
    text-align: start !important;
}

.icon_det_{
    border-radius: 48px;
    background: var(--primary-100, #ECF9F8);
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title_carddetails{
    color: var(--gray-900, #2F1952);

/* H5/Bold */
/* font-family: Poppins; */
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 36px */
}

.text_cardValue{
    color: var(--primary-600, #3EB6AE);

/* Body/Medium/Semibold */
/* font-family: Poppins; */
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
}


.det_value {
    color: var(--gray-900, #2F1952);

/* Body/Small/Regular */
/* font-family: Poppins; */
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
}

.det_element {
    color: var(--gray-400, #7B659F);

/* Body/Medium/Regular */
/* font-family: Poppins; */
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
}

/* .align-items-center{
    align-items: center;
}
.justify-content-center
{
    justify-content: center;
} */

.divider{
    border: 1px solid var(--gray-200, #E6E6E6);
    width: 100%;
    
}