/* do an animation on the background image scrolling */

.images_bg {
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    animation: bg 180s linear infinite;
    /* position: absolute; */
    /* top: 0; */
    height: 130px;
    /* left: 0; */
    /* fade from top and bottom */
    -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 45%, rgb(0, 0, 0) 65%, rgba(0, 0, 0, 0) 100%);
}
/* .hero .retroo{
    min-height: 230px;
} */
.hero .retroo p {
    text-align: center;
}

@media (min-width: 992px) {
    .images_bg {
        height: 400px;
        opacity: 1;
        
    }
    .hero .retroo{
        min-height: 400px;
    }
    .hero .retroo p {
        text-align: inherit;
    }
}



.hero {
    position: relative;
    /* top: -120px; */
    /* min-height: 462px; */
    display: grid;
    /* top and bottom have a gradient */
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%); */
}

.hero .btn-text-hero {
    /* margin-right: 1.85rem; */
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.7999999183;
    letter-spacing: 0.02rem;
    color: #3eb6ae;
    /* font-family: Poppins, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

}

@media (min-width: 992px) {
    .hero .btn-text-hero {
        justify-content: flex-start;
    }
}


@keyframes bg {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 0 200%;
    }
    100% {
        background-position: 0 0;
    }
}

@media (max-width: 991px) {
    .hero__title {
        font-size: 24px;
        text-align: center;
    }
}
.z-1{
    z-index: 1;
    /* background:linear-gradient(0deg, rgb(247 247 247) 0%, rgb(247 247 247 / 63%) 52%, rgb(247 247 247) 100%); */
}

.hero .tag {
    background: #2f19521a;
    /* Neutral/Soft Grey */
    /* border: 1px solid #F5F5F7; */
    color: #2f1952;
    border-radius: 24px;
    padding: 0.5rem 1rem;
    width: fit-content;
    font-size: 1rem;
    font-weight: 300;
    /* display: flex; */
    /* margin-bottom: 1.6rem; */
    /* align-items: center; */
}


.hero_pop {
    scrollbar-color: #3eb6ae transparent; 
}
.hero_pop .search {
    background: #FAFAFA;
    /* Neutral/Soft Grey */
    border-radius: 16px;
    padding: 1rem;
    display: flex;
    margin-bottom: 1.6rem;
        border: 1px solid #f1eeee;

}

.hero_pop .search input {
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.02rem;
    color: #959ead;
    /* font-family: Poppins, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
    background: transparent;
    width: 90%;
}

.hero_pop .search i {
    color: #959ead;
    font-size: 1rem;
    line-height: 1.7999999183;
    letter-spacing: 0.02rem;
    white-space: nowrap;
    flex-shrink: 0;
}

.hero_pop .devices_list {
    height: 400px;
    overflow: scroll;
}

/* separate the devices list items */
.hero_pop .devices_list div {
    margin-bottom: 1rem;
    border-bottom: 1px solid #f1eeee;
}


.hero_pop .mydevice {
    background: #fafafa;
    padding: 1rem;
    border-radius: 16px;
}

.hero_pop .supported {
    color: #3eb6ae;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;

}

.hero_pop .not_supported {
    color: #f44336;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;

}

.text-decoration-none {
    text-decoration: none;
    color: #3eb6ae;
}

.text-decoration-none:hover {
    text-decoration: none;
    color: #3eb6ae;
}
