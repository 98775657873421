.plan-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 24px;
    /* height: 146px; */
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 24px;
    position: relative;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .plan-bar {
        padding: 16px 4px;
        font-size: 1rem;
    }
}

.plan-bar .image {
    /* width: 100%; */
    height: 80px;
    width: 80px;
    border-radius: 4px;
    /* margin: auto; */
    display: block;
}

@media (max-width: 768px) {
    .plan-bar .image {
        height: 40px;
        width: 40px;
    }
}

.plan-bar .sec {
    /* min-width: 80px; */
    text-align: start;
    /* padding: 0 8px; */
}

.plan-bar .sec p {
    margin: 0;
}

.plan-bar i {
    font-size: 1.5rem;
    color: #3eb6ae;
}

.plan-bar .price {
    color: #3eb6ae;
}


/* hover  and active */


/* .plan-bar:hover, */

.plan-bar.active {
    background-color: #2F1952;
    color: #fff;
}


/* .plan-bar:hover i, */

.plan-bar.active i {
    color: #fff;
}


/* .plan-bar:hover .price, */

.plan-bar.active .price {
    color: #F0EB5B;
}

.plan-bar .recommended {
    background-color: #F0EB5B;
    color: #2F1952;
    border-radius: 16px;
    font-size: 1rem;
    position: absolute;
    text-align: center;
    top: -22px;
    right: 16px;
    padding: 12px;
    width: 174px;
    height: 45px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.plan-bar .recommendedar {
    background-color: #F0EB5B;
    color: #2F1952;
    border-radius: 16px;
    font-size: 1rem;
    position: absolute;
    text-align: center;
    top: -22px;
    left: 16px;
    padding: 12px;
    width: 174px;
    height: 45px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

@media (max-width: 991px) {
    .plan-bar .recommended {
        font-size: 0.8rem;
        width: 120px;
        height: 35px;
        padding: 8px;
    }

    .plan-bar .recommendedar {
        font-size: 0.8rem;
        width: 120px;
        height: 35px;
        padding: 8px;
    }
}