.d-flex .faq-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8px;
    border-radius: 12px
}

@media (max-width: 720px) {
    .dis-1 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
}
.price {
    color: var(--Primary-600, #3EB6AE);

/* Body/Large/Bold */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
}
.dis-1 {
    margin-bottom: 1rem;
}

.regulation-box{
    box-shadow: 0px 4px 40px 0px #0000000A;
    background-color: #fff;
    height: 72px;
    border-radius: 16px;
}
.elements_regulation
{
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0000000A;
    border-radius: 16px;
    padding: 20px;
    margin-top: 8px;
}

.elements_regulation .element_regulation
{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
}

.elements_regulation .element_regulation:last-child
{
    border-bottom: none;
}