/* 
import React from 'react';
//props
import './select.css'

interface SelectProps {
    options: string[];
    selected: string;
    onSelect: (option: string) => void;
}


const SelectOptions = (props: SelectProps) => {
    return (
        <div className="select-wrapper col-12 col-md-3 mb-3">
            <div className="input-select-dropdown">
                <div className="input-select-dropdown-selcted">
                    <span>{props.selected}</span>
                    <i className="fas fa-chevron-down"></i>
                </div>
                <div className="input-select-dropdown-options">
                    {
                        props.options.map((option: string) => (
                            <div className="input-select-dropdown-option" key={option} onClick={() => props.onSelect(option)}>
                                <span>{option}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectOptions; */

.select-wrapper {
    position: relative;
}

.input-select-dropdown {
    position: relative;
    width: 100%;
    height: 64px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    /* background-color: #FFFFFF; */
    cursor: pointer;
}

.input-select-dropdown-selcted {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    color: #2F1952;
    font-size: 16px;
    font-weight: 500;
}

.input-select-dropdown-selcted span {
    width: 100%;
    /* text-align: left; */
}

.input-select-dropdown-selcted i {
    font-size: 12px;
}

.input-select-dropdown-options {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

.input-select-dropdown-option {
    /* 
    font-size: 16px;
    font-weight: 500;
    */
    cursor: pointer;
    color: #2F1952;
    width: 100%;
    padding: 14px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    /* identical to box height, or 24px */
    letter-spacing: -0.02em;
    opacity: 0.5;
}

.input-select-dropdown-option-active {
    opacity: 1;
}

.input-select-dropdown-option:hover {
    background-color: #E0E0E0;
}

.input-select-dropdown-option:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.input-select-dropdown-option:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.input-select-dropdown-option:active {
    background-color: #E0E0E0;
}

.input-select-dropdown-option:active:hover {
    background-color: #E0E0E0;
}

.input-select-dropdown-option:active:active {
    background-color: #E0E0E0;
}

.input-select-dropdown-option:active:active:hover {
    background-color: #E0E0E0;
}

.input-select-dropdown-option:active:active:active {
    background-color: #E0E0E0;
}