.store .country-img
{
    width: 100%;
height: 748px;
border-radius: 16px;
background-size: cover;
}


@media (max-width: 991px) {
    .store .country-title{
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
    }
}   

.modal_store_info p
{
    opacity: 0.7;
    margin-bottom: 22px;
    font-size: 16px;
}
.modal_store_info h5
{
    margin: 0;
}

.modal_store_info .modal-content
{
    padding: 24px;
    border-radius: 16px;
}

.modal_store_info .modal-body
{
    padding: 0;
}

.modal_store_info .btn-close
{
    background-color: rgba(178, 178, 178, 0.507);
    border-radius: 50%;
    color: white;
}

.promo_code
{
    background-color: white;
    border-radius: 16px;
    padding: 18px;
    margin-bottom: 24px;
}

.bg_how{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    height: 75%;
}

.wpwl-form-card, .wpwl-form-directDebit{
    padding-left: 0 !important;
    padding-right: 0 !important;
}