/* .country {
    box-sizing: border-box;
    padding: 1.6rem;
    height: 100%;
    row-gap: 1.2rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 2.4rem;
    flex-shrink: 0;
} */

.country-wrapper {
    box-sizing: border-box;
    padding: 1.4rem;
    /* height: 100%; */
    row-gap: 1.2rem;
    /* display: flex; */
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 24px;
    flex-shrink: 0;
    cursor: pointer;
}

.countries {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: 136px;
    grid-gap: 1.4rem;
    grid-auto-flow: dense;
    /* padding: 1.6rem; */
    margin: 0 auto;
    /* max-width: 1200px; */
    width: 100%;
}

.country-wrapper-active {
    /* background-color: #3EB6AE; */
    /* color: #fff; */
    width: 636px;
    height: 340px;
    position: absolute;
    z-index: 1;
}

.country__image {
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}
.bold{
    font-weight: 600;
}

.country-wrapper .inner {
    display: none;
}

.country-wrapper-active .inner {
    display: block;
}

.country-wrapper .flag {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

.show_more {
    width: 382px;
    max-width: 100%;
    height: 69px;
    /* Primary/500 */
    background: #3EB6AE !important;
    color: #FFFFFF !important;
    /* Shadow */
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
}

@media (max-width: 720px) {
    .title_subtitle {
        margin-left: 16px;
    }
    [dir="rtl"] .title_subtitle {
        margin-right: 16px;
        margin-left: 0;
    }
    .countries {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 104px;
    }
    .country--active {
        width: calc(100% - 48px);
    }
    .country-wrapper-active {
        width: calc(100% - 48px);
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.06);
    }
    .country-wrapper {
        border-radius: 16px;
    }
    .container {
        padding: 0 24px;
    }
}

@media (max-width: 480px) {
    .country-wrapper {
        box-sizing: border-box;
        padding: 1rem;
    }
    .countries {
        grid-gap: 1rem;
    }
}

.ba_section {
    -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 45%, rgb(0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 45%, rgb(0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%);
    display: none;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: -1;
    height: 60%;
}

@media (max-width: 576px) {
    .ba_section {
        display: block;
    }
    .country-wrapper-active {
        height: initial;
    }
}

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    border-radius: 16px;
    min-height: 20px;
    width: 100px;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.country:hover .country-wrapper {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 4px 40px 0px #00000033;

}

.country-wrapper {
    height: 136px;
    transition: transform 0.5s;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.country-card-front,
.country-card-back {
    width: calc(100% - 46px);
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.country-card-front {
    z-index: 2;
}

.country-card-back {
    transform: rotateY(180deg);
}

.country-wrapper-active {
    transform: rotateY(180deg);
}

@media (max-width: 720px) {
    .country-wrapper {
        height: 104px;
    }
    .country-wrapper button {
        height: 48px;
    }
    .country:hover .country-wrapper {
        height: 104px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
        z-index: 2;
        padding: 16px;
    }
    .country-card-front,
    .country-card-back {
        width: calc(100% - 34px);
    }
}


.search__result {
    background: #ffffff;
    padding: 1.6rem;
    border-radius: 24px;
    position: absolute;
    width: 100%;
    box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.08);
}

.search__result__container {
    position: relative;
    width: 100%;
    z-index: 1000;
}

.search__result .search__result__item {
    display: grid;
    grid-template-columns: 64px 1fr;
    grid-gap: 1.6rem;
    align-items: center;
    cursor: pointer;
}

.search__result__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.6rem;
}

.search__result .search__result__item .search__result__item__img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.search__result svg {
    position: absolute;
    top: .3rem;
}

[dir="rtl"] .search__result svg {
    left: -0.5rem;
}

[dir="ltr"] .search__result svg {
    right: -0.5rem;
}

.search__result__item__name {
    position: relative;
    font-weight: 600;
}

.countries__section .search {
    background: #ffffff;
    /* Neutral/Soft Grey */
    border: 1px solid #F5F5F7;
    border-radius: 24px;
    padding: 1.2rem 1.6rem;
    display: flex;
    margin-bottom: 1.6rem;
    border: 2px solid #D1EFED
}

.countries__section .search input {
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.02rem;
    color: #959ead;
    /* font-family: Poppins, 'Source Sans Pro'; */
    white-space: nowrap;
    flex-shrink: 0;
    background: transparent;
    width: 90%;
}

.countries__section .search i {
    color: #959ead;
    font-size: 1.4rem;
    line-height: 1.7999999183;
    letter-spacing: 0.02rem;
    white-space: nowrap;
    flex-shrink: 0;
}


.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: no-repeat;
    border: none;
    color:#3EB6AE;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;

}
.nav-tabs {
    border-bottom: 2px solid #E8E8E8;
    
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active::after {
    height: 2px;
    width: 100%;
    background-color: #3EB6AE;
    content: "";
    display: block;
    position: relative;
    top: 9px;
    
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:active {
    background: no-repeat;
    border: none;
}
.nav-link:focus, .nav-link:hover, .nav-link {
    color: #7B659F;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent !important;
    isolation: auto;
    box-shadow: none !important;
}

.tab-content
{
    padding: 0 !important;
}

@media (max-width: 991px) {
    .nav-item {
        text-align: center;
        padding:  0;
    }
}

.black_bg {
    z-index: 8;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #16132633;
    top: 0;
    left: 0;
}

.search,.search__result__container {
    z-index:9;
    position: relative;
}