.bg-elements {
    top: 0;
    /* right: var(--end-0);
    left: var(--start-0);
    width: 100%;
    min-height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center; */
    top: -211px;
    left: -141px;
}

.elements {
    position: relative;
    width: 100%;
    height: 100%;
    scale: 0.9;
}

.elements .circle {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}


/* childs circles */

.elements :nth-child(1).circle {
    background: #E55EA2;
    color: #f2f2f2;
    width: 124px;
    height: 124px;
    left: 50px;
    bottom: 50px;
    font-size: 18px;
}

.elements :nth-child(2).circle {
    background: #845ECC;
    color: #f2f2f2;
    width: 210px;
    height: 210px;
    top: calc(50% - 105px);
    left: calc(50% - 105px);
    font-size: 36px;
}

.elements :nth-child(3).circle {
    background: #3EB6AE;
    color: #f2f2f2;
    width: 150px;
    height: 150px;
    right: 0;
    top: 0;
    font-size: 48px;
}

.elements :nth-child(4).circle {
    background: #593A89;
    color: #2F1952;
    width: 66px;
    height: 66px;
    top: 50px;
    left: 50px;
    font-size: 36px;
}

.elements :nth-child(5).circle {
    background: #F0EB5B;
    color: #f2f2f2;
    width: 86px;
    height: 86px;
    left: 50%;
    bottom: 0px;
    font-size: 18px;
}

.elements :nth-child(6).circle {
    background: #593A89;
    color: #f2f2f2;
    width: 66px;
    height: 66px;
    left: 70%;
    bottom: 120px;
    font-size: 18px;
}

.check_box {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    padding: 24px 24px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.check_box input[type="checkbox"] {
    min-width: 18px;
    height: 18px;
    /* margin-right: 10px; */
}


.check_box input[type="radio"] {
    width: 24px;
    height: 24px;
    /* margin-right: 10px; */
}


/* check box style */


/* radio button style */

.check_box input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #3EB6AE;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.check_box input[type="radio"]:checked:after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3EB6AE;
    margin: 4px auto;
}

    
.check_box input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #979797;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.check_box input[type="checkbox"]:checked {
    /* background: #3EB6AE; */
    border: 1.8px solid #3EB6AE;
    
}


/* add a checkmark/indicator when checked */

.check_box input[type="checkbox"]:checked:after {

  content: "✓";
      font-weight: 600;
      font-family: system-ui;
    font-size: 0.8rem;
    color: #3EB6AE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.check_box p {
    font-size: 18px;
    font-weight: 400;
    /* font-family: 'Poppins', sans-serif; */
}




.elements {
    /* width: 450px; */
    /* height: 450px; */
    border-radius: 50%;
}

.elements div {
    /* width: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    background-color: #2F1952;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -50px;
    border: 5px solid #fff;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.1); */
}

@keyframes rotate1 {
    0% {
        transform: rotate(0deg) translate(225px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translate(225px) rotate(-360deg);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotate(36deg) translate(225px) rotate(-36deg);
    }
    100% {
        transform: rotate(396deg) translate(225px) rotate(-396deg);
    }
}

@keyframes rotate3 {
    0% {
        transform: rotate(72deg) translate(225px) rotate(-72deg);
    }
    100% {
        transform: rotate(432deg) translate(225px) rotate(-432deg);
    }
}

@keyframes rotate4 {
    0% {
        transform: rotate(108deg) translate(225px) rotate(-108deg);
    }
    100% {
        transform: rotate(468deg) translate(225px) rotate(-468deg);
    }
}

@keyframes rotate5 {
    0% {
        transform: rotate(144deg) translate(225px) rotate(-144deg);
    }
    100% {
        transform: rotate(504deg) translate(225px) rotate(-504deg);
    }
}

@keyframes rotate6 {
    0% {
        transform: rotate(180deg) translate(225px) rotate(-180deg);
    }
    100% {
        transform: rotate(540deg) translate(225px) rotate(-540deg);
    }
}

@keyframes rotate7 {
    0% {
        transform: rotate(216deg) translate(225px) rotate(-216deg);
    }
    100% {
        transform: rotate(576deg) translate(225px) rotate(-576deg);
    }
}

@keyframes rotate8 {
    0% {
        transform: rotate(252deg) translate(225px) rotate(-252deg);
    }
    100% {
        transform: rotate(612deg) translate(225px) rotate(-612deg);
    }
}

@keyframes rotate9 {
    0% {
        transform: rotate(288deg) translate(225px) rotate(-288deg);
    }
    100% {
        transform: rotate(648deg) translate(225px) rotate(-648deg);
    }
}

@keyframes rotate10 {
    0% {
        transform: rotate(324deg) translate(225px) rotate(-324deg);
    }
    100% {
        transform: rotate(684deg) translate(225px) rotate(-684deg);
    }
}

.hero-container {
    min-height: 600px;
}

@media (max-width: 1199px) {
    .elements {
        scale: 0.7;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 487px;
    }
}

@media (max-width: 991px) {
    .elements {
        scale: 0.6;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 387px;
    }
    .hero_landin_page {
        text-align: center;
        font-size: small;
    }
}

@media (max-width: 767px) {
    .elements {
        scale: 0.4;
        height: 260px;
    }
    .hero_landin_page {
        text-align: center;
        font-size: small;
    }
    .hero_landin_page h1 {
        font-size: 1.7rem;
        font-weight: 800;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 256px;
    }
}

.offer_bg
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding:  32px 32px;
    /* border-radius: 8px; */
    background: #0F2135;
    color: #fff;
font-size: 1rem;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.20000000298023224px;

}

@media (max-width: 991px) {
    .offer_bg
    {
        height: auto !important;
        padding:  16px 16px;
    }

    .offer_bg p
    {
        font-size: 12px;
    }

    .offer_bg .btn-size-sm
    {
        /* width: 100%; */
        margin: auto;
        display: block;
        height: 40px;
        font-size: 12px;
        border-radius: 80px;
        padding: 0 16px;
        width: 230px;
    }

    .stepper h1
    {
        font-size: 2rem;
        margin-bottom: 16px;
    }

    .stepper .circle
    {
        border-radius: 50%;
        border: 2px solid #000000;
        transform: scale(0.8);
    }

    .stepper .shapess
    {
        display: none !important;
    }

    .stepper .providerss .itemprovider
    {
        flex-grow: 1;
    }


    .stepper .providerss .itemprovider img {
        max-height: 50px;
    }

    .stepper .bg-element--2
    {
        align-content: start !important;
    }

 
    
    /* check box style */

    
    
   
    
}
/* box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.1); */



.country-code 
{
    height: 72px;
    display: flex;
    background: #dadada;
    color: #0F2135;
    min-width: 104px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.20000000298023224px;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 14px 6px 6px 14px;
    gap: 8px;
}

.country-code img
{
width: 32px;
height: 32px;
border-radius: 50%;
}


.container-number-code {
    min-height: 72px;
    background: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 0;

}

.container-number-code input {
    border: none;
    
}

.country-code-text select {
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;

}