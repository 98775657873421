.esims_container {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


/* text-md-start */



@media (max-width: 720px) {
    .title_how_to {
        font-size: 2rem !important;
        margin: 1rem 0 !important;
        min-height: 3rem !important;
    }
}

@media (min-width: 768px) {
    [dir="rtl"] .text-md-start {
        text-align: right !important;
    }
    .text-md-start {
        text-align: left !important;
    }
}
@media (max-width: 768px) {   
    .info_ h2 {
        font-size: 1rem !important; 
    }
    .info_ {
        text-align: center !important;
    }
}