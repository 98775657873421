.header_order
{
    /* #3EB6AE */
    background-color: #3EB6AE;
    /* 32px, 80px, 32px, 80px */
    padding: 32px 80px 0px 80px;
    /* 12px */
    border-radius: 12px;
    color: #fff;
}

@media (max-width: 768px)
{
    .header_order
    {
        /* #3EB6AE */
        background-color: #3EB6AE;
        /* 32px, 80px, 32px, 80px */
        padding: 32px 24px 0px 24px;
        /* 12px */
        border-radius: 12px;
        color: #fff;
    }
}


 .order_tabs {
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
    padding: 1rem;
    border-radius: 1rem;
    background: #fff;
} 


/* .nav-link */


.input_gray
{
    background-color: #F6F3FC;
    border-radius: 12px;
    border: 0px;
    padding: 16px;
    width: 100%;
    margin-bottom: 16px;
} 