.container_circles {
    /* width: 450px; */
    height: 450px;
    border-radius: 50%;
}

.container_circles div {
    width: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    background-color: #2F1952;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -50px;
    border: 5px solid #fff;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.1);
}

@keyframes rotate1 {
    0% {
        transform: rotate(0deg) translate(225px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translate(225px) rotate(-360deg);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotate(36deg) translate(225px) rotate(-36deg);
    }
    100% {
        transform: rotate(396deg) translate(225px) rotate(-396deg);
    }
}

@keyframes rotate3 {
    0% {
        transform: rotate(72deg) translate(225px) rotate(-72deg);
    }
    100% {
        transform: rotate(432deg) translate(225px) rotate(-432deg);
    }
}

@keyframes rotate4 {
    0% {
        transform: rotate(108deg) translate(225px) rotate(-108deg);
    }
    100% {
        transform: rotate(468deg) translate(225px) rotate(-468deg);
    }
}

@keyframes rotate5 {
    0% {
        transform: rotate(144deg) translate(225px) rotate(-144deg);
    }
    100% {
        transform: rotate(504deg) translate(225px) rotate(-504deg);
    }
}

@keyframes rotate6 {
    0% {
        transform: rotate(180deg) translate(225px) rotate(-180deg);
    }
    100% {
        transform: rotate(540deg) translate(225px) rotate(-540deg);
    }
}

@keyframes rotate7 {
    0% {
        transform: rotate(216deg) translate(225px) rotate(-216deg);
    }
    100% {
        transform: rotate(576deg) translate(225px) rotate(-576deg);
    }
}

@keyframes rotate8 {
    0% {
        transform: rotate(252deg) translate(225px) rotate(-252deg);
    }
    100% {
        transform: rotate(612deg) translate(225px) rotate(-612deg);
    }
}

@keyframes rotate9 {
    0% {
        transform: rotate(288deg) translate(225px) rotate(-288deg);
    }
    100% {
        transform: rotate(648deg) translate(225px) rotate(-648deg);
    }
}

@keyframes rotate10 {
    0% {
        transform: rotate(324deg) translate(225px) rotate(-324deg);
    }
    100% {
        transform: rotate(684deg) translate(225px) rotate(-684deg);
    }
}

.container_circles div:nth-child(1) {
    animation: rotate1 80s linear infinite;
}

.container_circles div:nth-child(2) {
    animation: rotate2 80s linear infinite;
}

.container_circles div:nth-child(3) {
    animation: rotate3 80s linear infinite;
}

.container_circles div:nth-child(4) {
    animation: rotate4 80s linear infinite;
}

.container_circles div:nth-child(5) {
    animation: rotate5 80s linear infinite;
}

.container_circles div:nth-child(6) {
    animation: rotate6 80s linear infinite;
}

.container_circles div:nth-child(7) {
    animation: rotate7 80s linear infinite;
}

.container_circles div:nth-child(8) {
    animation: rotate8 80s linear infinite;
}

.container_circles div:nth-child(9) {
    animation: rotate9 80s linear infinite;
}

.container_circles div:nth-child(10) {
    animation: rotate10 80s linear infinite;
}

.container_circles div:last-child {
    width: 200px;
    height: 200px;
    margin: -100px;
}

.container_circles div:last-child:hover {
    width: 220px;
    height: 220px;
    margin: -110px;
}

.container_circles div:hover {
    width: 120px;
    height: 120px;
    transition: 0.3s;
    margin: -60px;
}

.container_circles {
    scale: 0.8;
}

.hero-container {
    min-height: 600px;
}

@media (max-width: 1199px) {
    .container_circles {
        scale: 0.7;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 487px;
    }
}

@media (max-width: 991px) {
    .container_circles {
        scale: 0.6;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 387px;
    }
    .hero_landin_page {
        text-align: center;
        font-size: small;
    }
}

@media (max-width: 767px) {
    .container_circles {
        scale: 0.4;
        height: 260px;
    }
    .hero_landin_page {
        text-align: center;
        font-size: small;
    }
    .hero_landin_page h1 {
        font-size: 1.6rem;
        font-weight: 800;
    }
    .bgas {
        background-size: 120% 120% !important;
        min-height: 256px;
    }
}

.offer_bg
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    padding:  32px 32px;
    /* border-radius: 8px; */
    background: #0F2135;
    color: #fff;
font-size: 1rem;
font-weight: 500;
line-height: 21px;
letter-spacing: 0.20000000298023224px;

}
@media (max-width: 991px) {
    

 
.hero_landin_page
.col-12 {
    padding-top: 0;
    padding-bottom: 0;
}
}
@media (max-width: 991px) {
    .offer_bg
    {
        height: auto !important;
        padding:  16px 16px;
        font-size: 14px;

    }
    .offer_bg p
    {
        font-size: 12px;
    }

    .offer_bg .btn-size-sm
    {
        /* width: 100%; */
        margin: auto;
        display: block;
        height: 40px;
        font-size: 12px;
        border-radius: 80px;
        padding: 0 16px;
        width: 230px;
    }
}