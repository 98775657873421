.payment__section .primary{
    height: 56px;
}

.payment_row input[type=number], .payment_row input[type=text],
.payment_row input[type=email], .payment_row input[type=tel]
{
    height: 72px;
    border-radius: 8px;
    border: 1px solid #d7d7d7;
    outline: none;
    background-color: #ffffff;
    padding: 0 16px;
    font-size: 1rem;
    /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08); */

}


@media (max-width: 768px)
{
    .payment_row input[type=number], .payment_row input[type=text],
    .payment_row input[type=email], .payment_row input[type=tel]
    {
        height: 62px;
    }
}

.payment_row input[type=number]:focus, .payment_row input[type=text]:focus{
    border: 1px solid #3EB6AE;
}

.payment_row label{
    /* font-family: Poppins; */
font-size: 16px;
font-weight: 600;
line-height: 44px;
letter-spacing: -0.01em;
/* text-align: left; */
}
@media (max-width: 768px)
{
    .payment_row label
    {
        line-height: 32px;
    }
}
.order-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #e5e5e55c;

}

.order-header span {
        color: var(--Gray-900, #2F1952);
        display: flex;
        align-items: center;

        /* Body/Medium/Semibold */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
    }
    .order-header  span::before {
        content: '';
        width: 16px;
        height: 32px;
        border-radius: 4px;
        background: #3EB6AE;
        display: inline-block;
        margin-right: 8px;

    }
    .order-header  svg {
        cursor: pointer;
    }
.otp_inputs input
{
    /* same height same width */
    height: 104px;
    width: 1064x;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #EDEDED;
    padding: 24px;
    font-size: 44px;
    /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);     */
    text-align: center;
    font-weight: bolder;

}

.otp_inputs input:focus
{
    border: 1px solid #FFB600;
}

.otp_inputs input::placeholder
{
    color: #B2B2B2;
}

.otp_inputs input::-webkit-outer-spin-button,
.otp_inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp_inputs input[type=number] {
    -moz-appearance: textfield;
}

.otp_inputs input[type=number]::-webkit-outer-spin-button,
.otp_inputs input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp_inputs {
    display: flex;
    margin-bottom: 24px;
    gap: 16px;
    /* center */
    align-items: center;
    justify-content: center;
    direction: ltr;
}

@media (max-width: 768px)
{

    .otp_inputs input
    {
        /* same height same width */
        height: 64px;
        width: 64px;
        padding: 16px;
        font-size: 1rem;
    }
}

.payment_row
.col-md-12
{
    margin-top: 1rem;
}

.modal_store_info .btn-close
{
    margin: 0;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
}