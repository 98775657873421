.prof .card-bg {
    height: 600px;
    background: #121221;
   /* Shadow/Card/10% */
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
}
@media (max-width: 576px) {
    .prof .card-bg {
        border-radius: 0px;
        height: 100%;
   }
    .prof .net {
        max-width: 150px;
   }
}
.prof .indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}
.prof .indicator {
    height: 5px;
    flex: 1 0 0;
    
}

.prof .indicator.active {
    background: #3eb6ae;
}

.prof .indicator {
    background: #ffffff74;
}
.prof .hidden {
    opacity: 0;
}
.prof *::-webkit-scrollbar {
    width: 6px;
   /* Set the width of the scrollbar */
    position: absolute;
    /* top: 0; */
    right: 10px;
    /* rotate: 24deg; */
}
.prof *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
   /* Set the background color of the track */
    width: 4px !important;
}
.prof *::-webkit-scrollbar-thumb {
    background: #fff;
    width: 5px !important;
}
.prof *::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.6);
   /* Set the color of the scrollbar thumb on hover */
}
.prof .content .back {
    width: 38px;
    height: 38px;
    transform-style: preserve-3d;
    transform: rotateY(180deg);
}
.prof .title_ h1 {
    background: linear-gradient(-90deg, #3eb6ae, #845ecc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.2rem;
    letter-spacing: -0.64px;
}
.prof .title_ p {
    font-size: 1rem;
}
.prof .service .logo {
    width: 24px;
    height: 24px;
}
.prof .service .next {
    position: absolute;
    bottom: 24px;
    right: 24px;
    height: 20px;
    width: 20px;
}
.prof .service h3 {
    line-height: 160%;
    letter-spacing: -0.4px;
}
.prof .service {
    gap: 8px;
    border-radius: 24px;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    transition: 300ms ease-in-out;
}
.prof .service:hover {
    scale: 1.04;
    box-shadow: 0px 0px 12px 0px #3eb6ae;
    background: radial-gradient(ellipse at top left, rgba(88, 255, 244, 0.2), rgba(255, 255, 255, 0.1));
}
.prof .options .togglesList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-gap: 10px;
}
.prof .option {
    cursor: pointer;
}
.prof .togglesList .option {
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.2px;
    align-self: center;
    background: #ffffff26;
    border-radius: 10px;
    padding: 22px;
}
.prof .option .checkbox {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    /* border: 1px solid #fff; */
    border: 1px solid #3eb6ae;
    padding: 4px;
    /* margin-right: 10px; */
}
.prof .option .checkbox .checked {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #3eb6ae;
}
.prof .button {
    width: 100%;
    display: flex;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #3eb6ae;
    cursor: pointer;
    color: #fff;
    font-size: 1rem;
   /* Updated from 14px */
    font-weight: 500;
    line-height: 150%;
    border: none;
    transition: 300ms ease-in-out;
}
.prof .button:hover {
    scale: 1.02;
    box-shadow: 0px 0px 20px 0px rgba(88, 255, 244, 0.6);
}
.prof .containersList .option {
    width: 100%;
    height: max-content;
    background: #2b2a39;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: -0.32px;
}
.prof .option .divider {
    width: 1px;
    height: 40px;
    background-color: #fff;
}
.prof .capacity {
    min-width: 90px;
}
.prof .net {
    display: block;
    margin: auto;
    max-width: 300px;
}
.prof .option .netvalue {
    color: #3eb6ae;
}
.prof .form-group input:not([type="checkbox"]),
.prof .form-group textarea {
    padding: 24px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    font-size: 1rem;
}
.prof .form-group.form-check {
    height: fit-content;
}
.prof .form-group input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
}
.prof .form-group label {
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0.5rem;
}
/* .prof .from-wrapper {
    height: 670px;
} */
.prof .from-wrapper > div {
    height: 100%;
}
/* @media (max-width: 992px) {
    .prof .from-wrapper {
        height: 100vh;
   }
} */

.prof p {
    margin-bottom: 0px;
}


.row-gap-4
{
    gap: 26px;
}

.row-gap-3 
{
    gap: 3rem;
}

.overflow-y-auto
{
    overflow-y: auto;
}

.column-gap-3
{
    column-gap: 16px;
}

.column-gap-4
{
    column-gap: 4rem;
}

.professionals__section {
    background: #0f071c;
    color: #fff;
    min-height: 500px;
}

.professionals__section .navbar-light .navbar-nav .nav-link {
    color: #fff;
}

@media (max-width: 992px) {
    .professionals__section .navbar-light .navbar-nav .nav-link {
        color: #000;
    }
    .professionals__section .nav-item {
        color: #000;
    }
}

.prof .options-data{
    margin: 0 -12px 0px -12px;
    padding: 0 12px 0px 12px;
}

.prof  input[type="checkbox"] {
    appearance: none;
    border: 1px solid #2F1952;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    min-width: 20px;
    height: 20px;
    background: #fff;
}

.prof  input[type="checkbox"]:checked {
        background: #3EB6AE;
        border: 1px solid #3EB6AE;
    
}

.prof  input[type="checkbox"]:checked:after {
    content: '\2713';
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.prof .privacy_label
{
    cursor: pointer;

}

/* @media (max-width: 992px) {
    .prof .button {
        position: fixed;
        width: calc(100% - 106px);
        bottom:24px;
        left: 86px;
    }
} */

.modal-90w {
    width: 992px;
    max-width: calc(100% - 16px);
}

/* arabic */
[dir="rtl"] .prof .service .next {
    left:24px;
    right: auto;
}