.plan-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 32px 24px; */
    /* height: 146px; */
    /* background-color: #fff; */
    /* border-bottom: 1px solid #e6e6e6; */
    /* border-radius: 24px; */
    position: relative;
    margin-bottom: 16px;
}


/* .plan-header .plan-header__image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #3eb6ae;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1rem;
} */

.plan-header .image {
    /* width: 100%; */
    height: 80px;
    width: 80px;
    border-radius: 16px;
}

@media (max-width: 768px) {
    .plan-header .image {
        height: 64px;
        width: 64px;
    }
    .plan-header {
        border-bottom: none;
    }
}

.plan-header .sec {
    min-width: 100px;
    text-align: start;
    padding: 0 16px;
}

.plan-header .sec p {
    margin: 0;
}

.plan-header i {
    /* font-size: 1.5rem; */
    /* color: #3eb6ae; */
}

.plan-header .price {
    color: #3eb6ae;
}


/* hover  and active */


/* .plan-header:hover,
.plan-header.active {
    background-color: #2F1952;
    color: #fff;
} */


/* .plan-header:hover i,
.plan-header.active i {
    color: #fff;
}

.plan-header:hover .price,
.plan-header.active .price {
    color: #F0EB5B;
} */


/* .plan-header .recommended {
    background-color: #F0EB5B;
    color: #2F1952;
    border-radius: 16px;
    font-size: 1rem;
    position: absolute;
    top: -22px;
    right: 32px;
    padding: 12px;
    width: 174px;
    height: 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
} */

.title_header_d 
{
    font-size: 1.5rem;
}