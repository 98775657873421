    footer {
        background-color: #F8F4FB;
        padding: 20px;
        text-align: start;
        padding: 64px 0;
    }
    
    footer .col-lg-4 img {
        margin: auto;
        display: block;
    }
    
    footer .col-lg-8 img {
        height: 64px;
    }
    
    .footer-form input {
        border: 3px solid #777E91;
        border-radius: 50px;
        outline: none;
        width: 100%;
        height: 48px;
        background-color: transparent;
    }
    
    .footer-form input::placeholder {
        color: #777E91;
        font-size: 1rem;
        line-height: 24px;
    }
    
    .footer-form button {
        background-color: #3EB6AE;
        position: absolute;
        top: 6px;
        right: 6px;
        border: none;
        outline: none;
        border-radius: 50px;
        height: 36px;
        width: 36px;
        color: #fff;
    }
    
    .border-top {
        border-top: 1px solid #777e9160 !important;
    }
    
    .footer_all {
        background-color: #161326 !important;
    }
    
    .footer_all i {
        color: #fff;
    }
    
    .footer_all h5 {
        color: #fff;
        font-size: 1rem;
        line-height: 48px;
        font-weight: 600;
    }
    .footer_all p {
        color: var(--Base-white, #FFF);
        /* Body/Large/Regular */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        letter-spacing: 0.2px;
        opacity: 0.4;
        
        
    }

    
    [dir=rtl] .footer-form button {
        left: 6px;
        right: auto;
    }
    
    @media (max-width: 991px) {
        .footer-form {
            max-width: 320px;
            margin: auto;
        }
        .nav-item {
            text-align: center;
            padding: 8px 0;
        }
        .nav {
            padding: 0;
        }
        .footer-form h5 {
            text-align: center;
        }
        .do_separator img {
            margin: auto;
            display: block;
        }
        .rights_footer {
            text-align: center;
            color: #fff;
        }
        .footer_all p {
            text-align: center;
        }
    }
    
    .iconsss a:hover svg {
        color: #3EB6AE !important;
        scale: 1.2;
        animation: 1s ease-in-out 0s infinite alternate none running pulse;
    }
    
    .iconsss {
        padding: 0;
    }

    .icon_rounded
    {
        display: flex;
padding: 12px;
align-items: flex-start;
gap: 8px;

border-radius: 48px;
background: var(--Primary-200, #D1EFED);
color: var(--Primary-500, #246B66);
    }