.plans-wrapper {
    box-sizing: border-box;
    padding: 1.4rem;
    /* height: 100%; */
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 24px;
    position: relative;
    margin-bottom: 16px;
    border-radius: 2.4rem;
    margin: 20px 0;
}

.shimmer-title{
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .circle
    {
        border-radius: 50%;
        border: 2px solid #000000;
        transform: scale(0.8);
    }
}