.bg-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vh;
    margin: auto;
    z-index: -1;
}

.bg-vv {
    background-color: #845ECC;
    background-position: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    height: 100%;
}
/* 
.container {
    position: relative;
    z-index: 1;
} */

.circle_bg {
    width: 70%;
    /* height as width to make it a circle */
    height: 70%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.264);
    display: flex;
    align-items: center;
    justify-content: center;
}

.adv_compare .plans_map .plans_item {
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    margin: 22px 0;
}

.adv_compare>h5 {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 30px */
    letter-spacing: -0.02em;
    color: #000000;
}

.adv_compare .plans_map .color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    /* margin-right: 16px; */
}

.adv_compare .plans_map .plans_item {
    display: flex;
    flex-direction: row;
}

.adv_compare {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.05);
    padding: 48px;
}

@media (max-width: 768px) {
    .adv_compare {
        padding: 16px;
        border-radius: 24px;
    }
}

.adv_compare ul {
    list-style: none !important;
    padding: 0;
}

.adv_compare .chart {
    width: 100%;
    max-width: 960px;
    height: 280px;
    margin: auto;
    position: relative;
}

.adv_compare .lines {
    width: 100%;
    position: unset;
    margin: 0;
}

.adv_compare .line {
    width: inherit;
    height: 2px;
    position: absolute;
    z-index: 3;
    margin-top: 16px;
    background: rgba(238, 238, 238, 0.6);
}

.adv_compare .line.l--0 {
    bottom: 0;
}

.adv_compare .line.l--25 {
    bottom: 15%;
}

.adv_compare .line.l--50 {
    bottom: 30%;
}

.adv_compare .line.l--75 {
    bottom: 60%;
}

.adv_compare .line.l--100 {
    bottom: calc(100% - 32px);
}

.adv_compare .line__label {
    width: 100px;
    position: absolute;
    bottom: 2px;
    left: 0;
}

.adv_compare .line__label.title {
    text-transform: uppercase;
    font-weight: bold;
}

.adv_compare .charts {
    height: 60%;
    width: 100%;
    position: inherit;
    top: 0;
    left: 0;
    z-index: 10;
}

.adv_compare .chart:not(:first-child) {
    margin-top: 10px;
}

.adv_compare .chart__title {
    display: block;
    margin: 0 0 10px;
    font-weight: bold;
    opacity: 0;
    animation: 1s anim-lightspeed-in ease forwards;
}

.chart--prod .adv_compare .chart__title {
    animation-delay: 3.3s;
}

.chart--design .adv_compare .chart__title {
    animation-delay: 4.5s;
}

.adv_compare .chart--horiz {
    display: flex;
    align-items: flex-end;
    height: 60%;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 78px);
    margin-bottom: 0;
}

.adv_compare .chart__bar {
    width: 56px;
    /* margin-bottom: 22px; */
    border-radius: 16px;
    opacity: 0;
    display: grid;
    align-items: center;
    padding-top: 10px;
    margin-left: 8px;
    animation: 1s anim-lightspeed-in ease forwards;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(11) {
    animation-delay: 2.7s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(10) {
    animation-delay: 2.5s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(9) {
    animation-delay: 2.3s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(8) {
    animation-delay: 2.1s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(7) {
    animation-delay: 1.9s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(6) {
    animation-delay: 1.7s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(5) {
    animation-delay: 1.5s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(4) {
    animation-delay: 1.3s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(3) {
    animation-delay: 1.1s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(2) {
    animation-delay: 0.9s;
}

.chart--dev .adv_compare .chart__bar:nth-of-type(1) {
    animation-delay: 0.7s;
}

.chart--prod .adv_compare .chart__bar:nth-of-type(2) {
    animation-delay: 4.2s;
}

.chart--prod .adv_compare .chart__bar:nth-of-type(1) {
    animation-delay: 4s;
}

.chart--design .adv_compare .chart__bar:nth-of-type(4) {
    animation-delay: 5.8s;
}

.chart--design .adv_compare .chart__bar:nth-of-type(3) {
    animation-delay: 5.6s;
}

.chart--design .adv_compare .chart__bar:nth-of-type(2) {
    animation-delay: 5.4s;
}

.chart--design .adv_compare .chart__bar:nth-of-type(1) {
    animation-delay: 5.2s;
}

.adv_compare .chart__label {
    width: 100%;
    color: white;
    background: #4cb8c4;
    border-radius: 12px;
    height: 24px;
    opacity: 0.6;
    animation: 1s anim-lightspeed-in ease forwards;
    display: block;
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 360px;
    padding: 0 10px;
}

@keyframes anim-lightspeed-in {
    0% {
        transform: translateX(-200%);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}