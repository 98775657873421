.topic-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 6px;
    /* width: 352px; */
    /* height: 208px; */
    border-radius: 24px;
    min-height: 190px;
}

.topic-box.active {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
}

.image_topic {
    -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0) 0%, rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(180deg, rgba(0, 0, 0) 0%, rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
}

.topics .gif {
    animation: none;
    animation-play-state: paused;
}

@media (max-width: 991px) {
    .topic-box {
        width: 100%;
        height: 100%;
        min-height: 0;
        padding: 16px;
        border-radius: 8px;
    }
    .topic-box p {
        font-size: 12px;
        color: #777E90;
    }
    .topic-box strong {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 500;
    }
    .topics h5 {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 500;
    }
    .topics h2 {
        font-weight: 600;
        font-size: 1rem;
        padding: 0 28px;
    }
}