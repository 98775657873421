.plan-item{
    padding: 24px 32px 24px 24px;
    border-radius: 24px;
    gap: 8px;
    background-color: #fff;
    margin-bottom: 24px;
}
.plan-item .text-muted
{
    color: #2f195266 !important;
}

.plan-item .buy_btn {
    width: 154px;
    height: 48px;
    padding: 12px 64px 12px 64px;
    /* border-radius: 16px; */
    gap: 10px;
}

@media (max-width: 991px) {
    .plan-item .buy_btn {
        width: 100%;
    }
}

.plan-item .info-logo {
    background: #D4ECEB66;
    /* width: Hug (36px)
height: Hug (36px) */
padding: 8px;
border-radius: 48px;
gap: 8px;
}

.plan-item .price_item {

    color: #3EB6AE;
    font-size: 16px;
    font-weight: 600;
    margin-top: 4px;
    display: inline-block;
}

.plan-item .info {
    font-size: 12px;
    
}