.midle_bg {
    background: radial-gradient(97.68% 929.1% at 50% 50%, #1B1931 0%, #060517 67.19%);
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    color: #fff;
    /* padding: 2rem 0; */
}

.midle .devider {
    background: #ffffff4e;
    height: 1px;
    width: 100%;
    margin: 2rem 0;
}

.midle p {
    color: #ffffff;
}

.midle__content {
    text-align: initial;
}

@media (max-width: 720px) {
    .midle__content {
        text-align: center;
    }
    .midle__content h2 {
        margin-top: 33px;
        font-size: 1.6rem;
    }
    .devider {
        display: none;
    }
    .download_btns img,
    .download_btns div {
        width: 100%;
    }
    .download_btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 32px;
    }
}