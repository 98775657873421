.esims_container {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@media (max-width: 991px) {
    .esims_container {
        background-image: none !important;
    }
}

.download_btn {
    height: 36px;
    padding: 22px;
    font-size: 12px;
    position: absolute;
    left: 84px;
    width: 110px;
    top: 27px;
}

.d-wol {
    position: sticky;
    bottom: 0;
    z-index: 5;
}


/* pros */
.pros_section {
    background: #161326;
    color: #fff;
    min-height: 500px;
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.random-size-1 {
    width: 20vmin;
    height: 20vmin;
}

.random-size-2 {
    width: 30vmin;
    height: 30vmin;
}

.random-size-3 {
    width: 40vmin;
    height: 40vmin;
}

.random-size-4 {
    width: 60vmin;
    height: 60vmin;
}

.random-size-5 {
    width: 80vmin;
    height: 80vmin;
}

.random-size-6 {
    width: 70vmin;
    height: 70vmin;
}


.background_animated {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /* background: #3E1E68; */
    overflow: hidden;
    opacity: 0.5;
    z-index: -1;
}

.background_animated span {
    /* width: 50vmin;
    height: 50vmin; */
    border-radius: 50vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 12;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background_animated span:nth-child(0) {
    color: #3EB6AE;
    top: 13%;
    left: 22%;
    animation-duration: 96s;
    animation-delay: -171s;
    transform-origin: -20vw 9vh;
    box-shadow: 100vmin 0 13.468779600070915vmin currentColor;
}
.background_animated span:nth-child(1) {
    color: #c850ff90;
    top: 35%;
    left: 40%;
    animation-duration: 87s;
    animation-delay: -457s;
    transform-origin: -10vw 0vh;
    box-shadow: 100vmin 0 13.416377529012953vmin currentColor;
}
.background_animated span:nth-child(2) {
    color: #3EB6AE;
    top: 69%;
    left: 38%;
    animation-duration: 73s;
    animation-delay: -340s;
    transform-origin: 1vw -8vh;
    box-shadow: 100vmin 0 12.972667176644268vmin currentColor;
}
.background_animated span:nth-child(3) {
    color: #c850ff90;
    top: 49%;
    left: 53%;
    animation-duration: 79s;
    animation-delay: -299s;
    transform-origin: 3vw -12vh;
    box-shadow: -100vmin 0 13.03202667908818vmin currentColor;
}
.background_animated span:nth-child(4) {
    color: #3EB6AE;
    top: 87%;
    left: 89%;
    animation-duration: 45s;
    animation-delay: -135s;
    transform-origin: 8vw -20vh;
    box-shadow: 100vmin 0 12.567868546517154vmin currentColor;
}
.background_animated span:nth-child(5) {
    color: #976be3a4;
    top: 78%;
    left: 81%;
    animation-duration: 66s;
    animation-delay: -286s;
    transform-origin: 25vw -2vh;
    box-shadow: 100vmin 0 12.592915487706222vmin currentColor;
}
.background_animated span:nth-child(6) {
    color: #3EB6AE;
    top: 56%;
    left: 41%;
    animation-duration: 53s;
    animation-delay: -268s;
    transform-origin: 1vw 19vh;
    box-shadow: 100vmin 0 13.033702591768666vmin currentColor;
}
.background_animated span:nth-child(7) {
    color: #c850ff90;
    top: 83%;
    left: 5%;
    animation-duration: 90s;
    animation-delay: -433s;
    transform-origin: -14vw -23vh;
    box-shadow: 100vmin 0 13.155114243179264vmin currentColor;
}
.background_animated span:nth-child(8) {
    color: #c850ff90;
    top: 49%;
    left: 2%;
    animation-duration: 31s;
    animation-delay: -232s;
    transform-origin: -11vw 5vh;
    box-shadow: -100vmin 0 13.199848581923035vmin currentColor;
}
.background_animated span:nth-child(9) {
    color: #976be3a4;
    top: 19%;
    left: 79%;
    animation-duration: 103s;
    animation-delay: -279s;
    transform-origin: 3vw 5vh;
    box-shadow: 100vmin 0 12.55377651408136vmin currentColor;
}
.background_animated span:nth-child(10) {
    color: #3EB6AE;
    top: 43%;
    left: 50%;
    animation-duration: 154s;
    animation-delay: -283s;
    transform-origin: -7vw 14vh;
    box-shadow: -100vmin 0 12.636853545004264vmin currentColor;
}
.background_animated span:nth-child(11) {
    color: #976be3a4;
    top: 79%;
    left: 47%;
    animation-duration: 153s;
    animation-delay: -320s;
    transform-origin: -14vw -6vh;
    box-shadow: -100vmin 0 12.731631794656417vmin currentColor;
}


.offer_title {
    /* font-size: 30px; */
    font-weight: 600;
    background: #3EB6AE;
    color: #fff;
    padding: 8px 16px;
    margin: 0  12px;
    border-radius: 50px;
    
}