.navbar .nav-item {
    margin: 28px 28px;
    font-size: 1rem;
    font-weight: 600;
}
.navbar-light .navbar-nav .nav-link{
    color: black;
}

.navbar
.navbar-brand
{
    margin-right: 40px;
}

[dir="rtl"]
.navbar
.navbar-brand
{
    margin-left: 40px;
    margin-right: 0;
}

.navbar {
    position: relative;
    z-index: 10;
}

.active .nav-link {
    color: #3EB6AE !important;
}

.navbar .nav-link:hover {
    color: #246B66 !important;
}

.navbar .nav-link,
.navbar .nav-item {
    font-weight: normal;
    font-size: 1rem;
}

.navbar .nav-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
}

.drawer-header {
    border-bottom: 1px solid #e6e6e6;
}

.collapse {}

@media (max-width: 768px) {
    .navbar .nav-item {
        margin: 0px;
        margin-top: 0px;
        font-size: 16px !important;
    }
    .navbar-nav {
        margin-top: 16px;
        padding: 0 !important;
    }
}