.download-section {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.download-section>div {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.download-section img {
    max-width: 100%;
}


/* @media (max-width: 991px) {
    .download-section {
        -moz-transform: none;
        -o-transform: none;
        -webkit-transform: none;
        transform: none;
        filter: none;
        -ms-filter: none;
        background-image: none !important;
    }
    .download-section>div {
        -moz-transform: none;
        -o-transform: none;
        -webkit-transform: none;
        transform: none;
        filter: none;
        -ms-filter: none;
    }
} */