.white_box_for_auction{
padding: var(--Padding-M, 24px) var(--Spacing-S, 16px);
border-radius: var(--Corner-Radius-Radius-BG_S, 16px);
background: var(--Base-white, #FFF);
box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
}

.top_bids{
    color: var(--Gray-900, #2F1952);
/* H6/Bold */

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
}

.user_name .avatar{
    width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 64px;
background: var(--Primary-500, #3EB6AE);
display: flex;
align-items: center;
justify-content: center;
color: var(--Base-white, #FFF);
font-weight: 700;
}

.user_name
{
    display: flex;
    align-items: center;
    margin-left: 8px;
    gap: 8px;
    color: var(--Gray-800, #472D71);

/* H6/Medium */
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}

.price {
    color: var(--Primary-600, #3EB6AE);
    font-weight: 700;
}