.glow-on-focus:hover {
    outline: none;
    box-shadow: 0 0 4px #3EB6AE;
}
.check_box_filtter {
    background: #FFFFFF;
    border-radius: 8px;
    width: fit-content;
    padding: 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.check_box_filtter input {
    width: 18px;
    height: 18px;
    /* margin-right: 10px; */
}


/* check box style */

.check_box_filtter input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #979797;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.check_box_filtter input[type="checkbox"]:checked {
    /* background: #3EB6AE; */
    border: 1.8px solid #3EB6AE;
    
}


/* add a checkmark/indicator when checked */

.check_box_filtter input[type="checkbox"]:checked:after {

  content: "✓";
      font-weight: 600;
      font-family: system-ui;
    font-size: 0.8rem;
    color: #3EB6AE;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


/* radio button style */

.check_box_filtter input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #2F1952;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.check_box_filtter input[type="radio"]:checked:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3EB6AE;
    margin: 4px auto;
}

.check_box_filtter p {
    font-size: 1rem;
    font-weight: 400;
    /* font-family: 'Poppins', sans-serif; */
}


/* bg-lg-white */

@media (min-width: 992px) {
    .bg-lg-white {
        background: #FFFFFF;
    }
}

.plans-filtter .button-select {
    font-size: 12px;
    font-weight: 500;
}