.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 24px;
    gap: 10px;
    /* box-shadow: 0px 6px 16px rgb(0 0 0 / 8%); */

    border-radius: 8px;
    width: fit-content;
    font-weight: 400;
    /* font-family: 'Poppins', sans-serif; */
}

.full-width {
    width: 100%;
}

.primary {
    background: #3EB6AE;
    color: #f2f2f2;
}

.secondary {
    background: #F7F7F7;
}

button:focus {
    outline: none !important;
    box-shadow: 0px 0px 0px 1px #3EB6AE !important;
}

button:hover {
    outline: none !important;
    box-shadow: 0px 0px 0px 1px #3EB6AE !important;
}


/* on hover the primary button should have a border of 1px and the color of the border should be #3EB6AE */

.primary:hover {
    color: white;
}

.circle {
    border-radius: 50%;
    padding: 0;
    width: 56px;
    height: 56px;
}

.icon {
    border-radius: 50%;
    padding: 0;
    width: 56px;
    height: 56px;
    box-shadow: none !important;
}

.outline {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid;
    border-color: #E8E8E8;
    height: 56px;
}

.rounded {
    border-radius: 50px !important;
    height: 56px;
}

.primary-outline {
    color: #3EB6AE;
    border: 1px solid #3EB6AE !important;
    background: rgba(62, 182, 174, 0.2);
}

.primary-outline-primary {
    color: #3EB6AE;
    border: 1px solid #3EB6AE !important;
}



.primary-outline:hover {
    color: #3EB6AE;
    border: 1px solid #3EB6AE !important;
    background: rgba(62, 182, 174, 0.2);
}

@media (max-width: 768px) {
    .btn {
        padding: 12px 12px;
    }
}

.btn:disabled {
    opacity: 1;
}

.square-btnn {
    border-radius: 8px !important;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    height: 69px;
}

.btn-no-shadow {
    box-shadow: none !important;
}

.c-white {
    color: #fff !important;
}


/* me-3 on arabic */

[dir="rtl"] .me-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
}

.btn-size-sm {
    font-size: 1rem;
    line-height: 150%;
    height: 48px;
}

.btn-size-md {
    font-size: 1rem;
    line-height: 150%;
    height: 56px;
}

.btn-size-lg {
    font-size: 18px;
    line-height: 150%;
    height: 64px;
}
