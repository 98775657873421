/* body {
    background-color: #FCFAFD;
} */


/* .plan-details {
    scale: 0.95;
} */

.plans-comparator {
    overflow-x: scroll;
}

.plans-comparator .element-plan {
    width: 326px;
    min-width: 326px;
    min-height: 64px;
    max-height: 196px;
    padding: 16px 12px;
}

.plans-comparator .first-row .element-plan .price {
    color: #3EB6AE;
}

.plans-comparator .first-column span {
    width: 326px;
    /* float: right; */
}

.plans-comparator .next-row {
    border-bottom: 1px solid #E0E0E0;
    width:fit-content;
}



.plans-comparator .next-row .element-plan>div {
    background-color: #E8E0F566;
    padding: 10px;
    /* border-radius: 5px; */
    min-height: 78px;
}


/* last row */

.plans-comparator .next-row:last-child {
    border-bottom: none;
}



.table_comp th:first-child,
.table_comp td:first-child,
.table_comp th.group-label,
.table_comp td.group-label,
.table_comp tr.group-row {
    position: sticky;
    left: 0;
    z-index: 1;
    /* background-color: #ffffff; */
    font-weight: 500;
}

[dir=rtl] .table_comp th:first-child,
[dir=rtl] .table_comp td:first-child,
[dir=rtl] .table_comp th.group-label,
[dir=rtl] .table_comp td.group-label,
[dir=rtl] .table_comp tr.group-row {
    left: auto;
    right: 0;
}

[dir=rtl] .ps-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
}

.table_comp tr.group-row {
    position: sticky;
    top: 40px;
    /* adjust the value as needed */
    z-index: 1;
    background-color: #E0E0E0 !important;
    white-space: normal;
}

.table_comp table {
    /* border-collapse: collapse; */
    width: 100%;
    table-layout: fixed;
}

.table_comp th,
.table_comp td {
    /* border: 1px solid #ccc; */
    padding: 8px;
    /* text-align: left; */
    /* width: 100px; */
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    max-width: 33vw;
    min-width: 33vw;
}

.table_comp thead th {
    background-color: #fcfafd;
    font-weight: bold;
    text-align: center;
    max-width: 33vw;
    min-width: 33vw;
}



.table_comp tr.group-row {
    background-color: #E0E0E0;
}

.table_comp th.group-label,
.table_comp td.group-label {
    background-color: #E0E0E0 !important;
    font-weight: bold;
    /* text-align: left; */
    /* margin-top: 16px !important; */
}


/* row color each 1 for 2 it's background-color: #eee; */

.table_comp tr:nth-child(odd) {
    background-color: #eff5f7;
}

.table_comp tr:nth-child(even) {
    background-color: #ffffff;
}


/* fist culomn odd row */

.table_comp tr:nth-child(odd) td:first-child {
    background-color: #eff5f7;
    box-shadow: 0 0 0 1px #eff5f7;
}

.table_comp tr:nth-child(even) td:first-child {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
}


/*  [rlt] pe-3 */
.column-primary > div
{
    background-color:  #DBCEF3 !important;
    border-radius: 16px;

}

.column-secondary > div
{
    background-color:  #BCA4EA !important;
    border-radius: 16px;
}
.column-primary > div > p ,
.column-secondary > div > p 
{
    color: var(--Gray-900, #2F1952) !important;
}