.card-advantages {
    box-sizing: border-box;
    padding: 24px;
    /* width: 38.6rem; */
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1.5rem 3rem rgb(43 43 43 / 2%);
    background-color: #ffffff;
    border-radius: 1.6rem;
    flex-shrink: 0;
}

.card-advantages img {
    margin-bottom: 2.4rem;
    width: 10rem;
    /* height: 16rem; */
    position: relative;
    object-fit: contain;
    vertical-align: top;
    flex-shrink: 0;
}

@media (max-width: 720px) {
    .card-advantages p {
        font-size: 1rem;
    }
    .card-advantages {
        padding: 8px;
    }
    .row_adv .col-6 {
        padding: 6px;
    }
    .card-advantages img {
        width: 7rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .card-advantages strong {
        height: 50px;
        display: block;
    }
    .advantages-esim-H1o {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    .advantages-esim-p-1 {
        font-size: .9rem;
        color: #9c9c9c;
    }
    .card-advantages p {
        color: #9c9c9c;
    }
}